import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const WebsiteDesignSEOServicePage = () => {
  return (
    <PageLayout>
      <SEO
        title="Front End Web Developer - Custom Software - Websuasion"
        ogDescription="Looking for a Front End Web Developer? Learn how ours use HTML5, CSS3, and JavaScript frameworks like ReactJS to build your responsive web application."
        image="/images/development/digital-transformation-consulting-firm.jpg"
      />
		  <TextBlock
		    textPadded
		    textLeft={
		      <div>
						<h1>Front End Web Developer</h1>
						<h3>Fractional Web App Development</h3>
						<p>
						Load a website in your web browser and click around. Every click that does something other than load a new page is the work of a front end web developer. They are in charge of the scripting and programming that runs inside your web browser. Utilizing <a href="https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5">HTML5</a>, <a href="https://developer.mozilla.org/en-US/docs/Web/CSS/CSS3">CSS3</a>, and <a href="https://en.wikipedia.org/wiki/JavaScript">JavaScript</a>, they create dynamic web applications. They allow users to create, read, update and delete data with ease from a single page.
						</p>
						<p>
						The front-end web developer goes beyond web design. Web Designers create marketing websites. Front-end developers program what happens after the user login. They build the user interface of custom web and mobile apps. Being tied to user interaction, they are often called <em>User Interface/User Experience Developers</em> (UI/UX).
						</p>
						<FormWrapper
		          modal
		          buttonProps={{
		            content: "Schedule An Introductory Call",
		            size: "tiny",
		            icon: "calendar alternate outline",
		          }}
		        >
		          <RequestMoreInfo />
		        </FormWrapper>
		      </div>
		    }
		    textRight={
		      <div>
					<img src="/images/development/front-end-developer-ui-ux.jpg" className="rounded-image" alt="Front End Web Developer - Custom Software - Websuasion" />
		      </div>
		    }
		  />
			<PageWrapper color="charcole">
			  <TextBlock
			    textPadded
			    textLeft={
			      <div>
							<h2>HTML5</h2>
							<p>
								HTML stands for <a href="https://en.wikipedia.org/wiki/HTML">Hyper Text Markup Language</a>. It is the basis of the world wide web. Tim Berners-Lee created HTML to semantically describe scientific documents. But as the web became mainstream, the public adopted the standard. HTML defines the behavior, placement, and styling of web page content. It implements a standardized set of rules all browsers understand. Known as tags, these HTML entities wrap content describing its intent to browsers.
							</p>
							<p>
								HTML communicates not only the website layout but the intent of the content. This is important in Search Engine Optimization. It's also important to keep website code organized.
							</p>
							<p>
								HTML5 was a significant upgrade to the HTML standard. It incorporates advanced functionality as compared to previous versions. Some of the features include video, audio, 3D graphics, and an interactive canvas.
							</p>
							<p>
								Both a Web Designer and Front-End Web Developer need extensive knowledge of HTML.
							</p>
						</div>
			    }
			    textRight={
			      <div>
						<img src="/images/development/front-end-developer-html5.jpg" className="rounded-image" alt="Front End Web Developer - Custom Software - Websuasion" />
			      </div>
			    }
			  />
			</PageWrapper>
			<PageWrapper color="white">
			  <TextBlock
			    textPadded
			    textLeft={
			      <div>
							<h2>CSS3</h2>
							<p>
								CSS stands for Cascading Style Sheets. Deep knowledge of CSS is critical to creating websites that are aesthetically pleasing. Cascading Style Sheets describe how the browser should present an HTML page. It provides instructions on colors, widths, heights, fonts, and alignment. CSS references standard HTML elements to control the general look and feel of a website. Developers can add specificity through the insertion of class attributes into the HTML. The attributes apply handles to the HTML elements. Style sheets can then target and manipulate only those classes.
							</p>
							<p>
								CSS3 is the newest implementation of the standard. It gave developers control over rounded corners, shadows, gradients, transitions, and animations. It also expanded layout behavior control over multi-columns and introduced the flexbox attribute.
							</p>
						</div>
			    }
			    textRight={
			      <div>
						<img src="/images/development/front-end-developer-stylesheets.jpg" className="rounded-image" alt="Front End Web Developer - Custom Software - Websuasion" />
			      </div>
			    }
			  />
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
						<h2>CSS Preprocessors</h2>
						<p>
							CSS Stylesheets are difficult to maintain. Enter the CSS Preprocessor. They allow developers to write lean stylesheet logic with variables and reusable code. Popular processors are SASS and LESS (we like <a href="https://sass-lang.com/">SASS</a>).
						</p>
						<p>
							A front-end developer can set up global defaults in one place for easy modification. Say you need to change all your branding colors across thousands of pages. With SASS or LESS, the developer can change it in one place within seconds. The processor generates the actual stylesheets for deployment anytime there is a change.
	          </p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
						<h2>Responsive Design And The Front End Web Developer</h2>
						<p>
							<a href="https://www.statista.com/statistics/241462/global-mobile-phone-website-traffic-share/">Over 50% of traffic in the United States is mobile</a>. That number is much higher in other countries. You don't want to lose more than half of your prospects. So, your site must change to conform to the screen size of the device in a usable way. HTML5 and CSS3 combine to solve this. They allow the front end web developer to control your website's layout based on ranges of screen size. It doesn't matter if your visitor is on a desktop, laptop, mobile phone or tablet. It doesn't matter what operating system or browser they use. They will have a good experience.
						</p>
						</div>
	        }
	      />
			  <TextBlock
			    textPadded
			    textLeft={
			      <div>
							<h2>JavaScript</h2>
							<p>
								Have you ever used an old shopping cart? Sometimes they warn you at checkout: "DON'T USE THE BACK BUTTON!" You might get charged twice. That's one of the many issues of non-Javascript sites. They were pretty clunky. If something on a page were to change, you only knew if you reloaded the page. Websites weren't very smart before JavaScript.
							</p>
							<p>
								JavaScript allows websites to be more visually creative through animations. It makes possible dynamic features. For example, notification indicators on Facebook show you new likes in real time. JavaScript makes Web 2.0 applications possible.
							</p>
							<p>
								Front-end developers use JavaScript in web design and development universally.
							</p>
						</div>
			    }
			    textRight={
			      <div>
						<img src="/images/development/front-end-developer-javascript.jpg" className="rounded-image" alt="Front End Web Developer - Custom Software - Websuasion" />
			      </div>
			    }
			  />
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<p>
								There are few modern websites that don't use it to some extent. JavaScript has a lot of strengths. It executes in the user's browser taking processing load off the web server. It works across operating systems and browsers. It is asynchronous. That means it does not have to wait for an action to complete before doing another action. Asynchronous code is more complex than synchronous code. But, the advantages far outweigh the extra work.
							</p>
							<h2>JavaScript Frameworks</h2>
							<p>
								Today, most front-end developers use JavaScript frameworks such as ReactJS and AngularJS. These frameworks provide a lot of out-of-the-box functionality. Their use lowers user interface development cost. They keep front-end code uniform across the application. They also assist developers in dealing with the complexities of asynchronous API calls.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>REST and API Servers</h2>
							<p>
								Representational State Transfer is a standardized method for communicating with a remote server. JavaScript runs in the browser. That means every call to a server behind the scenes is a remote connection. When that remote server follows this standard structure, it is RESTful. With REST, the front-end developer can make some basic assumptions. They know what behavior to expect when creating, reading, updating or deleting data.
							</p>
							<p>
								The traffic cop on the server for this communication is an API. The API authorizes each request. It also provides the rules by which the user interface has to play. API structure and development is usually the purview of the back-end developer. The front-end and <a href="/development/website-back-end-developer/">back-end developer</a> work together through the API.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
			  <TextBlock
			    textPadded
			    textLeft={
			      <div>
							<h2>Mobile Apps and the Front End Web Developer</h2>
							<p>
								On mobile and tablet devices, a web application can behave much like a typical mobile app. When allowed by the user, this can include access to the camera, photo library, microphone, and geolocation. But the user still has to access the website on their device through a web browser. And there is little connection to the device for features like notifications. Sometimes you need a mobile application.
							</p>
							<p>
								There are two types of mobile app: Native, and Hybrid.
							</p>
							<h3>Native Mobile Apps</h3>
							<p>
								Developers build native apps specifically for an operating system. For instance, they wite Android apps in Java - using the <a href="https://developer.android.com/studio">Android Studio</a> Integrated Development Environment (IDE). They write iOS apps for Apple devices in Objective-C and/or Swift using the <a href="https://developer.apple.com/xcode/">XCode</a> IDE. There are advantages to native design for resource-intensive applications. Games are an example. Also, apps that manipulate audio, video or photos.
							</p>
						</div>
			    }
			    textRight={
			      <div>
						<img src="/images/development/front-end-developer-mobile-apps.jpg" className="rounded-image" alt="Front End Web Developer - Custom Software - Websuasion" />
			      </div>
			    }
			  />
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<p>
								The downside is, you have to develop your app twice - once for each platform. They can not share code. And usually using different development teams specializing in each language. This doubles your cost. It adds another project management layer. And, it creates consistency issues across apps. That inconsistency can be bad for your customers and staff. And, the cost increase may be unnecessary.
							</p>
							<h3>Hybrid Mobile Apps</h3>
							<p>
								Most business apps deal with basic data. They have no heavy processing requirements. So, a "build once - deploy twice" hybrid strategy is often smart. A <a href="https://websuasion.com/mobile-app-development-company/">hybrid mobile app</a> is much like a web application. The difference is it connects to the mobile hardware through plugins. Plug-in libraries have satisfied almost every conceivable need these days. The front-end developer also has more creative power with interface design. They have access to the same JavaScript and CSS features available on the web. They are not limited by the operating system's IDE.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
			  <TextBlock
			    textPadded
			    textLeft={
			      <div>
							<h2>The Right and Left Brain Front End Web Developer</h2>
							<p>
								It's actually pretty rare to find someone who excels at both CSS and JavaScript. CSS is very visual-focused while JavaScript is very logical. This means that often you will need two user-interface developers working together. One will have more expertise with CSS. The other will be more focused on JavaScript. When two developers work together in this manner, we call it Pair Programming. A pair programming team can produce more quality work than the individual programmer.
							</p>
						</div>
			    }
			    textRight={
			      <div>
						<img src="/images/development/front-end-developer-left-brain-right-brain.jpg" className="rounded-image" alt="Front End Web Developer - Custom Software - Websuasion" />
			      </div>
			    }
			  />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default WebsiteDesignSEOServicePage
